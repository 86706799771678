import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import GWlp from "src/components/gwlp"
import BelowFold from "src/components/belowFold"
import LocalizedDisclaimer from "src/components/LocalizedDisclaimer"
import LocalizedFooter from "src/components/LocalizedFooter"

const GWlpData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Trouvez des Manuals à Lire et Imprimer</title>
    <style type="text/css">{`
.bf-module--belowFold .download-section .cta-button.primary {
		display: block;
  border: none;
  background: #2196f3;
  height: 65px;
  font-size: 25px;
  font-weight: 400;
  margin: 24px auto;
  border-radius: 2px;
  color: #fff;
	  }
.bf-module--belowFold .download-section .cta-button.primary:hover {
		background: #2196f3;
}
    #disclaimers-module--disclaimers {
      padding: 0;
      font-size: 12px;
      color: #333;
      text-align: center;
      margin: 0 auto !important;
      max-width: 554px;
      font-weight:400;
    }
    #bmpage-module--bmPage p {
      font-size: 12px;
      font-weight:400;
    }
   footer {
    font-family: 'Roboto', sans-serif !important;
    display: block;
    position: relative;
    color: #333;
    padding: 0;
    width: 100%;
    margin:0;
  }
  footer ul {
    position: relative;
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #333;
    font-size: 12px;
    padding: 12px 0;
  }
  footer ul li {
    padding: 0 4px 0 0;
  }
  footer ul li:last-child {
    padding: 0;
  }
  footer ul li:after {
    content: '|';
    padding: 0 0 0 4px;
  }
  footer ul li:nth-child(6):after, footer ul li:last-child:after {
    content: none;
    padding: 0;
  }
  footer ul a {
    color: #333;
  }
  @media only screen and (max-width: 768px) {

    footer {
      position: relative;
      display: flex;
      justify-content:center;
      align-items:center;
      color: #333;
      width: 100%;
      right:auto;
      bottom:auto;
  }

  footer ul {
    position: relative;
    display: flex;
    justify-content:center;
    align-items:center;
}
    `}
    </style>
    </Helmet>
    <section>
      <GWlp data={GWlpData}>
        <LocalizedDisclaimer language="fr" section="gwlp" />
      </GWlp>
      <BelowFold>
        <LocalizedDisclaimer language="fr" section="belowfold" />
      </BelowFold>
      <LocalizedFooter language="fr" />
    </section>
    </HomepageLayout>

  )
  }
